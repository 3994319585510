<template>
	<section class="row poster-rail noselect">
		<h1 v-if="title" class="poster-rail--title">
			<i v-if="icon" class="zmdi" :class="icon"></i>
			<span class="text">{{ title }}</span>
		</h1>
		<div class="swiper-top-link" v-if="url">
			<router-link :to="url" :title="'Ver todo en ' + title"
			>Ver Todo</router-link
			>
		</div>

		<div :id="uuid" class="slider rail-slider">
			<div
				class="slider-wrapper rail-slider--wrapper"
				:class="{ 'channel-selector': channelselector }"
			>
				<slot :data="items"></slot>
			</div>

			<button
				v-show="showPrev"
				v-on:click="onPrev()"
				type="button"
				class="rail-slider--btn btn-prev"
				title="Ir hacia atrás..."
			>
				<i class="zmdi zmdi-chevron-left" aria-hidden="true"></i>
				<span class="sr-only">Anterior</span>
			</button>

			<button
				v-show="showNext"
				v-on:click="onNext()"
				type="button"
				class="rail-slider--btn btn-next"
				title="Ir hacia adelante..."
			>
				<i class="zmdi zmdi-chevron-right" aria-hidden="true"></i>
				<span class="sr-only">Siguiente</span>
			</button>
		</div>
	</section>
</template>

<script>
export default {
	name: "Slider",

	props: {
		data: {
			type: Array,
			default: [],
			required: true,
		},
		url: {
			type: String,
			default: "",
			required: false,
		},
		title: {
			type: String,
			default: "",
			required: false,
		},
		icon: {
			type: String,
			default: "",
			required: false,
		},
		showactive: {
			type: Boolean,
			default: false,
			required: false,
		},
		channelselector: {
			type: Boolean,
			default: false,
			required: false,
		},
		channelid: {
			default: 0,
			required: false,
		},
		initialposition: {
			type: Number,
			required: false,
			default: 0,
		},
	},

	data() {
		return {
			dom: {
				slider: null,
				sliderWrapper: null,
			},
			domInfo: null,
			items: [],
			onMove: false,
			onInit: false,
			rango: {
				desde: 0,
				hasta: 1,
			},
			showPrev: false,
			showNext: false,
			esFinal: false,
			uuid: tplay.generateUUID(),
			debouncedInit: __.debounce(this.init, 500),
		};
	},

	created() {
		if (this.data.length > 0 && this.items.length === 0) {
			this.items.push(this.data[0]);
		}
	},

	mounted() {
		const self = this;

		//En el nextTick tengo garantizado que el DOM está cargado
		this.$nextTick(function () {
			self.dom.slider = document.getElementById(this.uuid);
			self.dom.sliderWrapper = self.$el.querySelector(".slider-wrapper");

			self.init();
		});

		window.addEventListener("resize", this.resize, true);
	},

	methods: {
		//Mueve el slider para mostrar el índice solicitado en la primera posición (a menos que quede lugar a la derecha).
		//Mantengo cargados en el dom los ítems anteriores y los próximos.
		//Siempre animo como si el ítem buscado estuviera en la tanda adyacente, así no tengo que cargar todos los intermedios.
		moveToItem(index, onFinish) {
			const self = this;
			const duracionAnimacion = 750; //milisegundos

			if (this.onMove || !this.data || this.data.length === 0) {
				return;
			}

			self.onMove = true;

			//Sanitizo el rango de index:
			let auxIndex = Math.max(0, index);
			auxIndex = Math.min(auxIndex, self.data.length - 1);

			//Tengo que terminar mostrando los ítems comprendidos entre los índices "desde" y "hasta":
			const hasta = Math.min(
				auxIndex + self.domInfo.itemsPerView + 1,
				self.data.length + 1
			); //Cota superior NO inclusiva (igual que Array.prototype.slice)
			let desde = Math.max(0, hasta - self.domInfo.itemsPerView - 1); //Cota inferior inclusiva

			self.esFinal = auxIndex + self.domInfo.itemsPerView > self.data.length;

			const end = function () {
				//Lo que dejo en ítems es lo que se muestra en el DOM.
				//Ahora que terminé la transición, dejo solamente lo que está visible.
				self.items = self.data.slice(desde, hasta);

				self.rango.desde = desde;
				self.rango.hasta = hasta;

				if (typeof onFinish === "function") {
					onFinish();
				}

				self.onMove = false;
			};

			if (!self.esFinal && desde === self.rango.desde && hasta === self.rango.hasta) {
				//No tengo que animar nada
				end();
				return;
			}

			//Agrego los datos que no estén en this.items actuamente (con un máximo de itemsPerView)
			const itemsLength = self.items.length;
			let datosNuevos;

			const animar = {
				origen: 0,
				destino: 0,
				cantidadItemsNuevosInicio: 0,
				cantidadItemsNuevosFinal: 0,
			};

			self.showNext = true;

			if (self.esFinal) {
				self.showNext = false;
			}

			//Uso concat en vez de unshift y push con apply, porque Vue no reacciona a esas modificaciones.
			//https://github.com/vuejs/vue/issues/5557
			//Otra solución sería usar this.$forceUpdate(), pero se siente medio "hacky".
			if (desde < self.rango.desde) {
				//Voy hacia la izquierda
				//Tengo que agregar los ítems al principio
				datosNuevos = self.data.slice(desde, Math.min(self.rango.desde, hasta));
				// Array.prototype.unshift.apply(self.items, datosNuevos);
				// self.$forceUpdate();

				self.items = datosNuevos.concat(self.items);

				animar.cantidadItemsNuevosInicio = datosNuevos.length;
				animar.origen = -animar.cantidadItemsNuevosInicio * self.domInfo.itemWidth;
				animar.destino = 0;
			} else {
				//Voy hacia la derecha
				//Tengo que agregar los ítems al final
				datosNuevos = self.data.slice(Math.max(self.rango.hasta, desde), hasta);
				// Array.prototype.push.apply(self.items, datosNuevos);
				// self.$forceUpdate();

				self.items = self.items.concat(datosNuevos);

				animar.cantidadItemsNuevosFinal = datosNuevos.length;

				if (self.esFinal && index != 0) {
					//Estoy al final. Tengo que moverme un ítem más, porque el último se está mostrando por la mitad

					desde++;
					animar.cantidadItemsNuevosFinal++;
				}

				animar.origen = 0;
				animar.destino = -animar.cantidadItemsNuevosFinal * self.domInfo.itemWidth;
			}

			if (
				(animar.cantidadItemsNuevosInicio == 0 &&
				animar.cantidadItemsNuevosFinal == 0) ||
				itemsLength < datosNuevos.length ||
				self.items.length <= self.domInfo.itemsPerView
			) {
				end();
				return;
			}

			self.$nextTick(function () {
				//Establezco el offset desde donde arranca la animación, ya que si voy hacia la izquierda,
				//al modificar this.items los ítems se van a agregar al principio del nodo del DOM, desplazando los que ya estaban
				self.dom.sliderWrapper.style.transition = "";
				self.dom.sliderWrapper.style.transform = "translate3d(" + animar.origen + "px, 0, 0)";

				//setTimeout para esperar a que se aplique la transformación de origen
				//TODO: implementar una forma correcta de esperar a que terminen las transiciones css
				setTimeout(function () {
					self.dom.sliderWrapper.style.transition = "transform " + duracionAnimacion + "ms ease 0s";
					self.dom.sliderWrapper.style.transform = "translate3d(" + animar.destino + "px, 0, 0)";

					//Espero a que se aplique la transformación de destino
					setTimeout(function () {
						self.$nextTick(function () {
							//Reseteo el transform
							self.dom.sliderWrapper.style.transition = "";
							self.dom.sliderWrapper.style.transform = "translate3d(0, 0, 0)";
						});

						end();
					}, duracionAnimacion + 50);
				}, 100);
			});
		},

		resize: function () {
			this.debouncedInit(true);
		},

		init(resize = false) {
			if (this.onMove || this.onInit) return;

			if (!this.data || this.data.length === 0) {
				this.items = [];
				return;
			}

			const self = this;
			this.onInit = true;

			const move = function () {
				//Si es un resize, mantengo la posición actual, sin hacer una búsqueda
				self.moveToItem(
					!self.showactive || resize ? self.rango.desde : self.findActiveIndex()
				);
				self.onInit = false;
			};

			if (!this.domInfo || resize) {
				//Si aún no había nada en items, cargo al menos el primero, así puedo calcular el ancho
				if (this.items.length === 0) {
					this.items.push(this.data[0]);
				}

				this.getDomInfo(function (info) {
					self.domInfo = info;
					move();
				});
			} else {
				move();
			}
		},

		findActiveIndex() {
			// recorro la data buscando el seleccionado o el presente
			// Si channelselector = true y se indica un índice, tengo que buscar el mismo.
			// Si channelselector = false o no se indica un índice, voy al elemento que tenga status === 'PRESENTE'.
			// En cualquier otro caso, voy al primer elemento.

			const l = this.data.length;

			if (typeof this.$options.propsData.initialposition !== "undefined") {
				//Si se seteó la propiedad, uso la misma como índice inicial
				return this.initialposition;
			} else if (this.channelselector && this.channelid != "") {
				for (let i = 0;i < l;i++) {
					if (this.data[i].idChannel === this.channelid) {
						return i;
					}
				}
			} else {
				for (let i = 0;i < l;i++) {
					if (this.data[i].status === "PRESENTE") {
						return i;
					}
				}
			}

			return Math.max(0, this.initialposition);
		},

		onPrev() {
			if (!this.domInfo) return;
			this.moveToItem(this.rango.desde - this.domInfo.itemsPerView);
		},

		onNext() {
			if (!this.domInfo) return;
			this.moveToItem(this.rango.desde + this.domInfo.itemsPerView);
		},

		totalWidth(el) {
			const style = el.currentStyle || window.getComputedStyle(el);

			const margin = {
				left: parseInt(style.marginLeft.replace("px", "")),
				right: parseInt(style.marginRight.replace("px", "")),
			};

			return el.offsetWidth + margin.left + margin.right;
		},

		//callback: function(info) {}
		getDomInfo(callback) {
			const self = this;
			let retryCount = 0;

			//Intento hasta que el dom esté listo.
			const retry = function () {
				const firstItem = self.$el.querySelector(".slider-item");

				if (firstItem) {
					//Asumo que el ancho de todos los ítems es igual. Uso el primero para calcularlo
					const itemWidth = self.totalWidth(firstItem);
					const itemsPerView = Math.floor(
						firstItem.parentNode.offsetWidth / itemWidth
					);

					callback({
						itemWidth: itemWidth,
						itemsPerView: itemsPerView,
					});
				} else {
					//Todavía el dom no está listo. Reintento:
					retryCount++;
					setTimeout(retry, retryCount > 2 ? 2000 : 250);
				}
			};

			retry();
		},
	},

	beforeDestroy() {
		if (this.dom.sliderWrapper) {
			this.dom.sliderWrapper.removeEventListener(
				"mouseenter",
				this.onMouseEnter,
				false
			);
			this.dom.sliderWrapper.removeEventListener(
				"mouseleave",
				this.onMouseLeave,
				false
			);
		}

		window.removeEventListener("resize", this.resize, false);
	},

	watch: {
		channelid: function () {
			//Necesito llamar a init() para que se mueva la animación hacia el canal seleccionado
			this.init();
		},

		initialposition: function () {
			//Necesito llamar a init() para que se mueva la animación hacia el ítem seleccionado
			this.init();
		},

		data: function () {
			this.init();
		},

		onMove: function (n) {
			if (n) {
				document.body.style.pointerEvents = "none";
				this.dom.slider.classList.add("on-move");
			} else {
				document.body.style.pointerEvents = "";
				this.dom.slider.classList.remove("on-move");
			}
		},

		"rango.desde": function (n) {
			this.showPrev = n > 0;
		},
		"rango.hasta": function (n) {
			this.showNext = n > 0;
		},

		esFinal: function (es) {
			this.showNext = !es;
		},
	},
};
</script>
